import Icon from 'components/atoms/Icon';
import { appSwitchRoutes } from 'constants/routes';
import { StageCandidateActionType } from 'generated/graphql';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Link, Tag, TagColor } from '@spotted-zebra-uk/ui-components';
import {
  isUntimedTest,
  LabelsType,
  mapTestToIcon,
  mapTestTypeToLabel,
  steps,
} from '../AccessibilityAdjustments.helpers';
import styles from './AccessibilityDetails.module.scss';

const AccessibilityDetails = ({
  tests,
  extraTime,
  stageCandidateId,
  handleProgress,
}: {
  tests: StageCandidateActionType[];
  extraTime: string;
  stageCandidateId: number;
  handleProgress: (step: steps) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // Set completedStep to 'details' in order to block backwards navigation with the
    // browser's back button
    handleProgress('details');
  }, [handleProgress]);

  const handleSubmit = () => {
    navigate(appSwitchRoutes.stage.getUrl(stageCandidateId));
  };

  return (
    <section className={styles.container} data-testid="accessibility-details">
      <p className={styles.description}>
        <Trans
          i18nKey="accessibilityAdjustments.details.description"
          values={{
            extraTime: t('accessibilityAdjustments.details.extraTime'),
          }}
          components={{ strong: <span /> }}
        />
      </p>
      <div className={styles.testsContainer}>
        {tests.map(test => (
          <TestItem key={test} testType={test} extraTime={extraTime} />
        ))}
      </div>
      <p className={styles.disclaimer}>
        {' '}
        {t('accessibilityAdjustments.details.disclaimer')}
      </p>
      <Link
        to="mailto:candidate@spottedzebra.co.uk"
        target="_blank"
        aria-label={t('common.sendEmailTo', {
          email: 'candidate@spottedzebra.co.uk.',
        })}
      >
        candidate@spottedzebra.co.uk
      </Link>
      <Button
        onClick={handleSubmit}
        type="submit"
        aria-label={t('common.continue')}
        data-testid="accessibility-summary__submit-button"
        className={styles.cta}
      >
        {t('common.continue')}
      </Button>
    </section>
  );
};

export default AccessibilityDetails;

const TestItem = ({
  testType,
  extraTime,
}: {
  testType: StageCandidateActionType;
  extraTime: string;
}) => {
  const { t } = useTranslation();
  const label = mapTestTypeToLabel[testType as LabelsType];
  const icon = mapTestToIcon[testType as LabelsType];

  return (
    <div className={styles.testItemContainer}>
      <div className={styles.leftSection}>
        <div className={styles.iconContainer}>
          <Icon icon={icon} />
        </div>
        <span className={styles.testType}>
          {t(`common.testTypes.${label}`)}
        </span>
      </div>
      <Tag
        isDismissible={false}
        isPresentational={true}
        tagColor={isUntimedTest(testType) ? TagColor.DISABLED : TagColor.BLUE}
        containerClass={styles.timeLabel}
        isDisabled={isUntimedTest(testType)}
      >
        {isUntimedTest(testType)
          ? t('accessibilityAdjustments.details.untimed')
          : extraTime + ' ' + t('accessibilityAdjustments.details.extraTime')}
      </Tag>
    </div>
  );
};
