import {
  DeiAgeRange,
  DeiEthnicity,
  DeiGender,
  DeiReligiousBelief,
  DeiSexualOrientation,
  DeiStageActionFieldsModel,
  DeiStageActionResponsesFragment,
  DeiYesNo,
  FieldType,
} from 'generated/graphql';
import { DeiFields, deiPayloadKeys } from './StageActionDeiForm.interfaces';

export const deiFieldQuestions: Record<
  keyof DeiFields,
  { label: string; key: deiPayloadKeys | ''; description?: string }
> = {
  hasGenderIdentityField: {
    label: 'What gender do you identify as?',
    key: 'genderIdentity',
  },
  hasTransgenderIdentityField: {
    label: 'Do you identify as transgender?',
    key: 'hasTransgenderIdentity',
  },
  hasSexualOrientationField: {
    label: 'Which of the following best describes your sexual orientation?',
    key: 'sexualOrientation',
  },
  hasEthnicityField: {
    label: 'What ethnicity do you identify as?',
    key: 'ethnicity',
  },
  hasReligiousBeliefsField: {
    label: 'Which of the following best describes your religion or beliefs?',
    key: 'religiousBelief',
  },
  hasDisabilityField: {
    label:
      'Do you identify as a person with a disability or long-term condition?',
    description:
      'Disabilities and long-term conditions can take many forms, including but not limited to physical, sensory, cognitive, mental health, and chronic illnesses.',
    key: 'hasDisability',
  },
  hasNeurodiversityField: {
    label: 'Do you identify as a neurodiverse person?',
    description:
      'Neurodiversity refers to the natural variation in the human brain and cognition. Examples of neurodiverse conditions include, but are not limited to, Autism Spectrum Disorder (ASD), ADHD, dyslexia, dyspraxia, dyscalculia, and Tourette syndrome.',
    key: 'hasNeurodiversity',
  },
  hasAgeRangeField: { label: 'What is your age group?', key: 'ageRange' },
  __typename: { label: '', key: '' },
};

const genderLabels = {
  [DeiGender.Male]: 'Male',
  [DeiGender.Female]: 'Female',
  [DeiGender.Intersex]: 'Intersex',
  [DeiGender.NonBinary]: 'Non-binary',
  [DeiGender.GenderQueer]: 'Gender queer / Gender non-conforming',
  [DeiGender.Other]: 'Other',
  [DeiGender.PreferNotToSay]: 'Prefer not to say',
};

const sexualityLabels = {
  [DeiSexualOrientation.Asexual]: 'Asexual',
  [DeiSexualOrientation.Bisexual]: 'Bisexual',
  [DeiSexualOrientation.Gay]: 'Gay',
  [DeiSexualOrientation.Straight]: 'Heterosexual / Straight',
  [DeiSexualOrientation.Lesbian]: 'Lesbian',
  [DeiSexualOrientation.Pansexual]: 'Pansexual',
  [DeiSexualOrientation.Queer]: 'Queer',
  [DeiSexualOrientation.Other]: 'Other',
  [DeiSexualOrientation.PreferNotToSay]: 'Prefer not to say',
};

const ethnicityLabels = {
  [DeiEthnicity.Asian]:
    'Asian (Indian, Pakistani, Bangladeshi, Chinese or other Asian background)',
  [DeiEthnicity.Black]: 'Black, Caribbean or African',
  [DeiEthnicity.Hispanic]: 'Hispanic or Latinx',
  [DeiEthnicity.IndigenousPeoples]: 'Indigenous Peoples',
  [DeiEthnicity.MiddleEasternNorthAfrican]: 'Middle Eastern / North African',
  [DeiEthnicity.Mixed]: 'Mixed or multiple ethnic groups',
  [DeiEthnicity.PacificIslander]: 'Pacific Islander',
  [DeiEthnicity.White]: 'White',
  [DeiEthnicity.Other]: 'Other',
  [DeiEthnicity.PreferNotToSay]: 'Prefer not to say',
};

const religiousBeliefLabels = {
  [DeiReligiousBelief.Agnosticism]: 'Agnosticism',
  [DeiReligiousBelief.Buddhism]: 'Buddhism',
  [DeiReligiousBelief.Christianity]: 'Christianity',
  [DeiReligiousBelief.Hinduism]: 'Hinduism',
  [DeiReligiousBelief.Islam]: 'Islam',
  [DeiReligiousBelief.Judaism]: 'Judaism',
  [DeiReligiousBelief.Secular]: 'Secular/Non-religious',
  [DeiReligiousBelief.Sikhism]: 'Sikhism',
  [DeiReligiousBelief.Spiritual]: 'Spiritual but not religious',
  [DeiReligiousBelief.Other]: 'Other',
  [DeiReligiousBelief.PreferNotToSay]: 'Prefer not to say',
};

const ageLabels = {
  [DeiAgeRange.N18_30]: '18-30',
  [DeiAgeRange.N31_40]: '31-40',
  [DeiAgeRange.N41_50]: '41-50',
  [DeiAgeRange.N51_65]: '51-65',
  [DeiAgeRange.N65Plus]: '65+',
  [DeiAgeRange.PreferNotToSay]: 'Prefer not to say',
};

const yesNoLabels = {
  [DeiYesNo.Yes]: 'Yes',
  [DeiYesNo.No]: 'No',
  [DeiYesNo.PreferNotToSay]: 'Prefer not to say',
};

const createSelectOptions = <T extends Record<string, string>>(
  labelsObject: T
): { label: string; value: keyof T }[] =>
  (Object.keys(labelsObject) as Array<keyof T>).map(key => ({
    label: labelsObject[key],
    value: key,
  }));

const yesNoOptions = createSelectOptions(yesNoLabels);

const mapFieldToOptions = {
  hasGenderIdentityField: createSelectOptions(genderLabels),
  hasTransgenderIdentityField: yesNoOptions,
  hasSexualOrientationField: createSelectOptions(sexualityLabels),
  hasEthnicityField: createSelectOptions(ethnicityLabels),
  hasReligiousBeliefsField: createSelectOptions(religiousBeliefLabels),
  hasDisabilityField: yesNoOptions,
  hasNeurodiversityField: yesNoOptions,
  hasAgeRangeField: createSelectOptions(ageLabels),
};

// Fields object contains not only the selected questions but all possible ones, this function
// will filter out the unused questions and then sort the fields in the desired order
const filterAndSortUsedFields = (fields: DeiStageActionFieldsModel) => {
  const orderedKeys = Object.keys(deiFieldQuestions);
  return Object.entries(fields)
    .filter(
      elem =>
        fields[elem[0] as keyof DeiStageActionFieldsModel] &&
        elem[0] !== '__typename'
    )
    .sort((a, b) => orderedKeys.indexOf(a[0]) - orderedKeys.indexOf(b[0]));
};

export const getDeiFieldsWithOptions = (fields: DeiStageActionFieldsModel) => {
  const sortedFieldsWithOptions = filterAndSortUsedFields(fields).flatMap(
    field => {
      const { label, key, description } =
        deiFieldQuestions[field[0] as keyof DeiFields];

      // Add question label, description and select options to the DEI field
      const newField = {
        id: key,
        label: label,
        description: description,
        options: mapFieldToOptions[field[0] as keyof typeof mapFieldToOptions],
        type: FieldType.SingleSelectField,
      };

      // If genderIdentity is a question in the form, the hasTransgenderIdentity question is also added along with it
      if (field[0] === 'hasGenderIdentityField') {
        return [
          newField,
          {
            id: 'hasTransgenderIdentity',
            label: deiFieldQuestions.hasTransgenderIdentityField.label,
            options: mapFieldToOptions.hasTransgenderIdentityField,
            type: FieldType.SingleSelectField,
          },
        ];
      }

      return newField;
    }
  );

  return sortedFieldsWithOptions;
};

const deiKeysToFields: Record<deiPayloadKeys, keyof typeof mapFieldToOptions> =
  {
    genderIdentity: 'hasGenderIdentityField',
    hasTransgenderIdentity: 'hasTransgenderIdentityField',
    sexualOrientation: 'hasSexualOrientationField',
    ethnicity: 'hasEthnicityField',
    religiousBelief: 'hasReligiousBeliefsField',
    hasDisability: 'hasDisabilityField',
    hasNeurodiversity: 'hasNeurodiversityField',
    ageRange: 'hasAgeRangeField',
  };

export const initializeDeiValues = (
  pastResponses: DeiStageActionResponsesFragment
) => {
  // Get responses that are not null
  const filteredResponses = Object.entries(pastResponses).filter(
    elem => elem[0] !== '__typename' && elem[1]
  );

  const prefilledValues = filteredResponses.reduce((acc, key) => {
    return Object.assign(acc, {
      [key[0]]: getSelectedOptionByField(key[0], key[1]),
    });
  }, {});

  return prefilledValues;
};

const getSelectedOptionByField = (
  fieldKey: string,
  fieldValue: string | null
) => {
  const field = deiKeysToFields[fieldKey as deiPayloadKeys];
  return (
    mapFieldToOptions[field].find(item => item.value === fieldValue) || {
      label: '',
      value: '',
    }
  );
};
